// fetchRemoteConfigList.js
'use strict';
import fetch from '../resource/customFetch.js';
import setConfigurations from '../action/setConfigurations.js';

import { CONFIG_PRIORITY_CONFIGURE } from '../resource/configPriority.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';

import {
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
} from '../ActionTypes.js';

const selectPath = ['config', 'configure'];

/**
 * Fetch remote config list
 * @kind action
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @return {Promise} Action promise.
 */
const fetchRemoteConfigList =
  ({ httpProxyHeaders = {} } = {}) =>
  async dispatch => {
    const fetchOptions = {
      method: 'GET',
      headers: {
        ...getHeaders(),
        ...httpProxyHeaders,
      },
    };

    const url = getResourceUrl({ endpoint: '/configure.json' });

    dispatch({ type: SET_NETWORKING_FETCHING, payload: { selectPath } });
    try {
      const response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        await handleFetchError({ response });
      }

      const data = await response.json();

      dispatch({ type: SET_NETWORKING_SUCCESS, payload: { selectPath } });

      return dispatch(
        setConfigurations({
          configData: data,
          priority: CONFIG_PRIORITY_CONFIGURE,
        })
      );
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath, error },
      });
    }
  };

export default fetchRemoteConfigList;
