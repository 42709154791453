// Home.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import withWindowResize from '../resource/withWindowResize.js';
import { HomeCategoryUi } from '../resource/feedConstants.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

import PullToRefresh from '../container/PullToRefresh.js';
import FeedComponent from '../container/FeedComponent.js';
import UniversalContainer from '../component/UniversalContainer.jsx';
import { parseCategoryString } from '../component/FeedComponent.jsx';
import { headerHeight } from '../style/variables.js';
import { getIsIphoneXSeriesPWA } from '../resource/getUserAgent.js';
import SeoHelmetWithTDK from '../component/SeoHelmetWithTDK.jsx';
import HomeJsonLd from '../component/HomeJsonLd.jsx';
import HomeLivestreamJsonLd from '../component/HomeLivestreamJsonLd.jsx';
import HomeVideoJsonLd from '../component/HomeVideoJsonLd.jsx';
import HomeSearchBar from '../container/HomeSearchBar.js';

import media from '../style/media.js';
import getIsInAutoPlayArea from '../resource/getIsInAutoPlayArea.js';
import { fromEvent, animationFrameScheduler, from } from 'rxjs';
import {
  debounceTime,
  pairwise,
  map,
  switchMap,
  reduce,
  filter,
  startWith,
} from 'rxjs/operators';

const tdkMap = {
  default: {
    title: 'seo-home-title',
    description: 'seo-home-description',
    h1: 'seo-home-h1',
    jsonLd: <HomeJsonLd />,
  },
  '/livestream': {
    title: 'seo_livestream_title',
    description: 'seo_livestream_description',
    h1: 'seo_livestream_h1',
    jsonLd: <HomeLivestreamJsonLd />,
  },
  '/video': {
    title: 'seo_video_title',
    description: 'seo_video_description',
    h1: 'seo_video_h1',
    jsonLd: <HomeVideoJsonLd />,
  },
};

const GAP_DEFAULT_SIZE = 16;
const PSEUDO_TRENDING_HASHTAG = 'pseudo_trending_hashtag';
const PSEUDO_TRENDING_CATEGORY = 'pseudo_trending_category';

const Gap = styled.div`
  margin-bottom: ${({ marginBottom }) =>
    marginBottom || `${GAP_DEFAULT_SIZE}px`};
`;
const CategoryRowWrapper = styled(Gap)`
  position: relative;
  ${({ isCategoryWidthLimited }) => {
    if (isCategoryWidthLimited) {
      return css`
        margin: 16px auto;
        width: 100%;
        max-width: 680px;
      `;
    }
  }}
`;

class Home extends React.PureComponent {
  state = {
    shouldRefresh: false,
  };
  flixRowList = [];

  constructor(props) {
    super(props);
    // fetch data for SSR
    const {
      staticContext,
      remoteConfigCategories,
      jumboVideoFeedName,
      trendingHashtagFeed,
      trendingCategoryFeed,
      isFirstFeedFetched,
      configListError,
      fetchRemoteConfigList,
      fetchFeeds,
    } = props;
    if (staticContext.actions) {
      const hasCategories = remoteConfigCategories.length > 0;
      if (!hasCategories && !configListError) {
        staticContext.actions.push({
          function: fetchRemoteConfigList,
          argumentObject: {
            httpProxyHeaders: staticContext.httpProxyHeaders,
          },
        });
      } else if (hasCategories && !isFirstFeedFetched) {
        const feeds = remoteConfigCategories.filter(
          category => !category.startsWith('pseudo_')
        );
        if (jumboVideoFeedName) {
          // insert as first item to prevent interfere checking grid livestream feeds
          feeds.splice(0, 0, jumboVideoFeedName);
        }
        if (
          trendingHashtagFeed &&
          remoteConfigCategories.includes(PSEUDO_TRENDING_HASHTAG)
        ) {
          // insert as first item to prevent interfere checking grid livestream feeds
          feeds.splice(0, 0, trendingHashtagFeed);
        }
        if (
          trendingCategoryFeed &&
          remoteConfigCategories.includes(PSEUDO_TRENDING_CATEGORY)
        ) {
          // insert as first item to prevent interfere checking grid livestream feeds
          feeds.splice(0, 0, trendingCategoryFeed);
        }
        staticContext.actions.push(
          ...feeds
            .filter(
              (feed, index) =>
                // we skip the fetching here, let component/HomeLiveStreamFeed do it instead.
                !(
                  /^livestream_|^user_/.test(feed) && index === feeds.length - 1
                )
            )
            // prevent SSR fetch too many feeds, and client download too many images before js load
            .slice(0, 5)
            .map(feed => {
              const isShowcaseFeed = feed === jumboVideoFeedName;
              const result = {
                argumentObject: {
                  page: 1,
                  // showcase's limit should as same as container/HomeShowcaseList.js
                  limit: isShowcaseFeed ? 100 : undefined,
                  httpProxyHeaders: staticContext.httpProxyHeaders,
                },
              };
              result.function = fetchFeeds;
              result.argumentObject.type = feed;
              return result;
            })
        );
      }
    }
  }

  getInCenterIndexObservable$ = deltaY =>
    from(this.flixRowList).pipe(
      reduce((acc, data, index) => {
        if (!data) return acc;
        return getIsInAutoPlayArea({ ref: data.ref }) ? acc.concat(index) : acc;
      }, []),
      map(inCenterIndex => ({ inCenterIndex, deltaY }))
    );

  getShouldPlayIndex = ({ deltaY, inCenterIndex }) => {
    if (inCenterIndex.length <= 0) return null;
    const shouldPlayIndex =
      deltaY >= 0 ? Math.max(...inCenterIndex) : Math.min(...inCenterIndex);
    return shouldPlayIndex ? this.flixRowList[shouldPlayIndex].feedId : null;
  };

  getWindowEventSubscrption = event => {
    return fromEvent(window, event)
      .pipe(
        startWith(null),
        debounceTime(200, animationFrameScheduler),
        map(() => window.pageYOffset),
        pairwise(),
        map(pair => pair[1] - pair[0]),
        filter(deltaY => Math.abs(deltaY) >= 10),
        switchMap(deltaY => this.getInCenterIndexObservable$(deltaY)),
        map(data => this.getShouldPlayIndex(data))
      )
      .subscribe(feedId => this.props.setShouldPlayFeedId({ feedId }));
  };

  componentDidMount() {
    this.scrollSubscrption = this.getWindowEventSubscrption('scroll');
    this.resizeSubscrption = this.getWindowEventSubscrption('resize');
  }

  componentWillUnmount() {
    this.scrollSubscrption?.unsubscribe();
    this.resizeSubscrption?.unsubscribe();
  }

  handleRefresh = async ({ reset }) => {
    this.setState({
      shouldRefresh: true,
    });
    setTimeout(() => {
      reset();
      this.setState({
        shouldRefresh: false,
      });
    });
  };

  getRefreshProps = () => {
    const { shouldRefresh } = this.state;
    return { shouldRefresh };
  };

  /**
   * @param {String} entry entry in config array string
   * @param {Number} index index of the entry
   */
  renderConfigRow = (entry, index) => {
    const Component = (
      <FeedComponent
        {...this.getRefreshProps()}
        category={entry}
        index={index}
        isInPaginationMode
      />
    );

    if (entry.startsWith('pseudo_')) {
      return Component;
    } else {
      return this.renderCategory(entry, index);
    }
  };

  renderCategory = (category, index) => {
    const { remoteConfigCategories, livestreamFeedName } = this.props;
    const isLastCategory =
      remoteConfigCategories.filter(a => a).length - 1 === index;
    const { ui } = parseCategoryString({
      category: category,
      isLivestreamFeed: category === livestreamFeedName,
      isLastCategory,
    });
    if (HomeCategoryUi.FLIX === ui) {
      return (
        <div
          ref={el => {
            if (el) {
              this.flixRowList[index] = {
                ref: el,
                feedId: category,
              };
            }
          }}
        >
          <FeedComponent
            {...this.getRefreshProps()}
            category={category}
            index={index}
            isLastCategory={isLastCategory}
            isInPaginationMode
          />
        </div>
      );
    }
    return (
      <FeedComponent
        {...this.getRefreshProps()}
        category={category}
        index={index}
        isLastCategory={isLastCategory}
        isInPaginationMode
      />
    );
  };

  render() {
    const {
      t,
      pathname,
      remoteConfigCategories,
      tabsComponent,
      shouldRenderSearchBar,
      defaultOgImageUrl,
      defaultOgTitle,
      defaultOgDescription,
      isCategoryWidthLimited,
    } = this.props;
    const configRows = remoteConfigCategories.filter(a => a);
    const isIphoneXSeriesPWA = getIsIphoneXSeriesPWA();
    const shouldRenderSearchBarWrapper = /^\/(video[/]?)?$/.test(pathname);
    const tdk = tdkMap[pathname] || tdkMap.default;
    const rssFeeds = configRows
      .filter(c => !/^pseudo_/.test(c))
      .map(c => ({
        feedId: c,
        title: t(c, {
          ns: TranslationNamespace.FEED,
        }),
      }));
    return (
      <StyledHome isIphoneXSeriesPWA={isIphoneXSeriesPWA}>
        <SeoHelmetWithTDK
          title={t(defaultOgTitle || tdk.title, {
            ns: TranslationNamespace.SEO,
          })}
          description={t(defaultOgDescription || tdk.description, {
            ns: TranslationNamespace.SEO,
          })}
          feeds={rssFeeds}
          shouldAddSearchOnOgUrl={
            !!(defaultOgImageUrl || defaultOgTitle || defaultOgDescription)
          }
        />
        {tdk.jsonLd}
        <HeadingOne>
          {t(tdk.h1, {
            ns: TranslationNamespace.SEO,
          })}
        </HeadingOne>

        {tabsComponent && <UniversalContainer filename={tabsComponent} />}

        {shouldRenderSearchBarWrapper && (
          <SearchBarWrapper>
            {shouldRenderSearchBar && <HomeSearchBar />}
          </SearchBarWrapper>
        )}
        <PullToRefresh useWindow onRefresh={this.handleRefresh}>
          <>
            {/* Rows from config */}
            {configRows.map((homeCategoryId, index) => {
              return (
                <CategoryRowWrapper
                  // Avoid to use incorrect style on hydration
                  className={`categoryId_${homeCategoryId}`}
                  key={`${index}:${homeCategoryId}`}
                  isCategoryWidthLimited={isCategoryWidthLimited}
                >
                  {this.renderConfigRow(homeCategoryId, index)}
                </CategoryRowWrapper>
              );
            })}
          </>
        </PullToRefresh>
      </StyledHome>
    );
  }
}

Home.propTypes = {
  staticContext: PropTypes.object,
  pathname: PropTypes.string,
  livestreamFeedName: PropTypes.string,
  jumboVideoFeedName: PropTypes.string,
  trendingHashtagFeed: PropTypes.string,
  trendingCategoryFeed: PropTypes.string,
  defaultOgImageUrl: PropTypes.string,
  defaultOgTitle: PropTypes.string,
  defaultOgDescription: PropTypes.string,
  tabsComponent: PropTypes.string,
  remoteConfigCategories: PropTypes.array,
  leaderboardIds: PropTypes.array,
  isFirstFeedFetched: PropTypes.bool,
  isCategoryWidthLimited: PropTypes.bool,
  configListError: PropTypes.instanceOf(Error),
  setShouldPlayFeedId: PropTypes.func,
  fetchRemoteConfigList: PropTypes.func,
  fetchFeeds: PropTypes.func,
  t: PropTypes.func.isRequired,
  shouldRenderSearchBar: PropTypes.bool,
};

Home.defaultProps = {
  staticContext: {},
  pathname: '',
  livestreamFeedName: null,
  jumboVideoFeedName: null,
  trendingHashtagFeed: null,
  trendingCategoryFeed: null,
  defaultOgImageUrl: '',
  defaultOgTitle: '',
  defaultOgDescription: '',
  tabsComponent: '',
  remoteConfigCategories: [],
  leaderboardIds: [],
  isFirstFeedFetched: false,
  isCategoryWidthLimited: false,
  configListError: null,
  setShouldPlayFeedId: () => null,
  fetchRemoteConfigList: () => null,
  fetchFeeds: () => null,
  shouldRenderSearchBar: false,
};

const StyledHome = styled.div`
  position: relative;
  padding-top: calc(${headerHeight}px + var(--space-to-pinned-notification));
  // (2024.10) padding-bottom is now useless since it has had footer.
  min-height: 100vh;
`;

const HeadingOne = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  font-size: 16px;
  color: #191919;
`;

const SearchBarWrapper = styled(Gap)`
  & > div {
    display: none;
  }
  ${media.tablet`
    padding: 0px 16px;
    margin: 12px 0;
    & > div {
      display: block;
    }
  `};
  ${media.mobile`
    margin: 8px 0 12px;
    & > div {
      display: block;
    }
  `};
`;

export default withTranslation()(withWindowResize(Home));
