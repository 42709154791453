// PullToRefresh.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Spinning from '../style/Spinning.js';
import loadingIcon from '../../img/img-loading-tealblue-20.svg';

const STATE = {
  IDLE: 'idle',
  PULLING: 'pulling',
  RELEASING: 'releasing',
  REFRESHING: 'refreshing',
};

class PullToRefresh extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      state: STATE['IDLE'],
      top: -48,
      rotate: 0,
      opacity: 0,
      isTop: true,
    };

    this.pullStartY = null;
    this.pullMoveY = null;
    this.timeout = null;
    this.dist = 0;
    this.distResisted = 0;
  }

  componentDidMount() {
    this.attachListeners();
  }
  componentWillUnmount() {
    this.detachListeners();
    clearTimeout(this.timeout);
  }

  attachListeners() {
    window.addEventListener('touchstart', this.handleTouchStart);
    window.addEventListener('touchmove', this.handleTouchMove, {
      passive: false,
    });
    window.addEventListener('touchend', this.handleTouchEnd);
    window.addEventListener('scroll', this.handleScroll);
  }
  detachListeners() {
    window.removeEventListener('touchstart', this.handleTouchStart);
    window.removeEventListener('touchmove', this.handleTouchMove, {
      passive: false,
    });
    window.removeEventListener('touchend', this.handleTouchEnd);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleTouchStart = event => {
    const { touches } = event;
    const { state } = this.state;
    if (state === STATE['IDLE']) {
      if (this.shouldPullToRefresh()) {
        this.pullStartY = touches[0].screenY;
      }
    }
  };
  handleTouchMove = event => {
    const { touches } = event;

    if (!this.pullStartY) {
      if (this.shouldPullToRefresh()) {
        this.pullStartY = touches[0].screenY;
      }
    } else {
      this.pullMoveY = touches[0].screenY;
    }

    const { state } = this.state;

    if (state === STATE['REFRESHING']) {
      if (
        this.shouldPullToRefresh() &&
        this.pullStartY < this.pullMoveY &&
        event.cancelable
      ) {
        event.preventDefault();
      }

      return;
    }

    if (state === STATE['IDLE']) {
      this.setState({ state: STATE['PULLING'] });
    }

    if (this.pullStartY && this.pullMoveY) {
      this.dist = this.pullMoveY - this.pullStartY;
    }

    this.distExtra = this.dist - 0;

    if (this.distExtra > 0) {
      if (event.cancelable) {
        event.preventDefault();
      }

      this.distResisted = 0.35 * this.distExtra - 48;
      let rotate = Math.floor(this.distExtra);
      let opacity = Math.min(Math.max(this.distResisted / 60, 0.3), 1);
      this.setState({ top: this.distResisted });
      this.setState({ rotate });
      this.setState({ opacity });

      if (state === STATE['PULLING'] && this.distResisted > 60) {
        this.setState({ state: STATE['RELEASING'] });
      }

      if (state === STATE['RELEASING'] && this.distResisted < 60) {
        this.setState({ state: STATE['PULLING'] });
      }
    }
  };
  handleTouchEnd = () => {
    const { state } = this.state;
    if (state === STATE['RELEASING'] && this.distResisted > 60) {
      this.setState({ state: STATE['REFRESHING'], top: 50 });

      this.timeout = setTimeout(() => {
        const retval = this.props.onRefresh({ reset: () => this.reset() });

        if (retval && typeof retval.then === 'function') {
          retval.then(() => this.reset());
        }
      }, 500);
    } else {
      if (state === STATE['REFRESHING']) {
        return;
      }

      this.reset();
    }

    this.pullStartY = null;
    this.pullMoveY = null;
    this.dist = 0;
    this.distResisted = 0;
  };

  handleScroll = () => {
    this.setState({ isTop: this.shouldPullToRefresh() });
  };

  setChildRef = ref => {
    const { postChildRef } = this.props;
    this.mainElement = ref;
    postChildRef && postChildRef(ref);
  };

  reset = () => {
    this.setState({ state: STATE['IDLE'], top: -48 });
  };

  shouldPullToRefresh = () => {
    const { useWindow, disabled } = this.props;

    if (disabled) {
      return false;
    }

    if (useWindow) {
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      return !top;
    }

    return this.mainElement && !this.mainElement.scrollTop;
  };

  render() {
    const { state, top, rotate, isTop, opacity } = this.state;
    const child = React.Children.only(this.props.children);
    const cloneProps = { ref: this.setChildRef };

    return (
      <React.Fragment>
        <StyledPullToRefresh
          top={top}
          isTop={isTop}
          opacity={opacity}
          shouldTransition={
            state === STATE['PULLING'] || state === STATE['RELEASING']
          }
        >
          <LoaderWrapper rotate={rotate}>
            <Loader
              isRefreshing={state === STATE['REFRESHING']}
              src={loadingIcon}
            />
          </LoaderWrapper>
        </StyledPullToRefresh>
        {React.cloneElement(child, cloneProps)}
      </React.Fragment>
    );
  }
}

PullToRefresh.propTypes = {
  children: PropTypes.node,
  useWindow: PropTypes.bool,
  disabled: PropTypes.bool,
  onRefresh: PropTypes.func,
  postChildRef: PropTypes.func,
};

PullToRefresh.defaultProps = {
  children: '',
  useWindow: false,
  disabled: false,
  onRefresh: ({ reset }) => reset(),
  postChildRef: () => null,
};

const StyledPullToRefresh = styled.div.attrs(({ top, opacity }) => ({
  style: {
    top,
    opacity,
  },
}))`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  background-color: #fff;
  transform: translateX(-50%);
  z-index: 1000;
  ${({ isTop }) =>
    isTop
      ? css`
          touch-action: pan-x pan-down pinch-zoom;
        `
      : null};

  ${({ shouldTransition }) =>
    shouldTransition
      ? css`
          transition: none;
        `
      : css`
          transition: top 0.3s;
        `};
`;

const LoaderWrapper = styled.div.attrs(({ rotate }) => ({
  style: {
    transform: `rotate(${rotate}deg) `,
  },
}))`
  width: 80%;
  height: 80%;
`;

const Loader = styled.img`
  width: 100%;
  height: 100%;
  animation: 0.5s infinite ${Spinning};
  animation-timing-function: linear;
  animation-play-state: ${({ isRefreshing }) =>
    isRefreshing ? 'running' : 'paused'};
`;

export default PullToRefresh;
