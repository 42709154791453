// SeoHelmetWithTDK.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';

import withRouter from '../component/WithRouter.jsx';

import { RSS_FEED } from '../resource/resourceUrlTypeConstants.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getIsInBrowserMainThread } from '../resource/getJsEnvironment.js';
import { TRACK_UTM_LIST } from '../resource/fetchOptionHeader.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import defaults from '../resource/defaults.js';

const isClient = getIsInBrowserMainThread();

const renderFeedLink = ({ feedId, title }) => {
  if (!feedId) {
    return null;
  }
  const feedUrl = getResourceUrl({
    endpoint: `/feeds/${feedId}`,
    resourceType: RSS_FEED,
  });
  feedUrl.pathname = `${feedUrl.pathname}.rss`;
  return (
    <link
      rel="alternate"
      type="application/atom+xml"
      key={feedId}
      title={title}
      href={feedUrl.href}
    />
  );
};

export const SeoHelmetWithTDK = ({
  title = '',
  description = '',
  keywordsString = '',
  feedId = '',
  feeds = defaults.EMPTY_ARRAY,
  image = '',
  imageWidth = null,
  imageHeight = null,
  staticContext = defaults.EMPTY_OBJECT,
  location = defaults.EMPTY_OBJECT,
  shouldAddSearchOnOgUrl = false,
  t,
  titleOnly = false,
}) => {
  if (titleOnly) {
    const defaultTitle = t('seo-home-title', {
      ns: TranslationNamespace.SEO,
    });

    return (
      <Helmet>
        <title>{title || defaultTitle}</title>
      </Helmet>
    );
  }

  const ssrHostname = staticContext?.requestHeaders?.hostname || 'swag.live';
  const hostname = isClient ? window.location.hostname : ssrHostname;
  const ogTitle =
    title ||
    t('seo-home-title', {
      ns: TranslationNamespace.SEO,
    });
  const searchParams = new URLSearchParams(
    shouldAddSearchOnOgUrl ? location.search : ''
  );
  [...searchParams.keys()]
    .filter(key => !TRACK_UTM_LIST.includes(key.toLowerCase()))
    .forEach(key => {
      searchParams.delete(key);
    });
  const search = searchParams.toString();
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {title ? <meta property="og:title" content={ogTitle} /> : null}
      {/* set description to empty string to avoid fallback */}
      {description || description === '' ? (
        <meta name="description" content={description} />
      ) : null}
      {description || description === '' ? (
        <meta property="og:description" content={description} />
      ) : null}
      {keywordsString ? (
        <meta name="keywords" content={keywordsString} />
      ) : null}
      <meta
        property="og:url"
        content={`https://${hostname}${location.pathname ?? ''}${
          search ? `?${search}` : ''
        }`}
      />
      {image ? <meta property="og:image" content={image} /> : null}
      {image ? <meta property="twitter:image" content={image} /> : null}
      {imageWidth ? (
        <meta property="og:image:width" content={imageWidth} />
      ) : null}
      {imageHeight ? (
        <meta property="og:image:height" content={imageHeight} />
      ) : null}
      {renderFeedLink({ feedId, title })}
      {feeds.map(renderFeedLink)}
    </Helmet>
  );
};

SeoHelmetWithTDK.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywordsString: PropTypes.string,
  feedId: PropTypes.string,
  feeds: PropTypes.arrayOf(
    PropTypes.shape({
      feedId: PropTypes.string.isRequired,
      title: PropTypes.string,
    })
  ),
  image: PropTypes.string,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  staticContext: PropTypes.object,
  location: PropTypes.object,
  shouldAddSearchOnOgUrl: PropTypes.bool,
  t: PropTypes.func.isRequired,
  titleOnly: PropTypes.bool,
};

export default withTranslation()(withRouter(SeoHelmetWithTDK));
