// HomeVideoJsonLd.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import serialize from 'serialize-javascript';
import { withTranslation } from 'react-i18next';

import { TranslationNamespace } from '../resource/translationNamespace.js';

export const HomeVideoJsonLd = ({ t }) => {
  return (
    <Helmet>
      <script type="application/ld+json" helmetkey="jsonld">
        {serialize(
          {
            '@context': 'https://schema.org',
            '@graph': [
              {
                '@type': 'WebPage',
                '@id': 'https://swag.live/video#webpage',
                url: 'https://swag.live/video',
                name: t('seo_video_title', {
                  ns: TranslationNamespace.SEO,
                }),
                breadcrumb: {
                  '@id': 'https://swag.live/video#breadcrumb',
                },
              },
              {
                '@type': 'BreadcrumbList',
                '@id': 'https://swag.live/video#breadcrumb',
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    name: t('seo-home-title', {
                      ns: TranslationNamespace.SEO,
                    }),
                    item: 'https://swag.live',
                  },
                  {
                    '@type': 'ListItem',
                    position: 2,
                    name: t('seo_video_title', {
                      ns: TranslationNamespace.SEO,
                    }),
                    item: 'https://swag.live/video',
                  },
                ],
              },
            ],
          },
          { isJSON: true }
        )}
      </script>
    </Helmet>
  );
};

HomeVideoJsonLd.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(HomeVideoJsonLd);
