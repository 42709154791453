// PullToRefresh.js
import { connect } from 'react-redux';
import PullToRefresh from '../component/PullToRefresh.jsx';

import getModalData from '../selector/getModalData.js';

const mapStateToProps = (state, { disabled }) => {
  const hasModal = getModalData(state, 'hasModal');
  return {
    disabled: hasModal || disabled,
  };
};

export default connect(mapStateToProps)(PullToRefresh);
