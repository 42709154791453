// HomeJsonLd.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import serialize from 'serialize-javascript';
import { withTranslation } from 'react-i18next';

import { getCoporation } from '../resource/SchemaObject.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';

export const HomeJsonLd = ({ t }) => {
  return (
    <Helmet>
      <script type="application/ld+json" helmetkey="jsonld">
        {serialize(
          {
            '@context': 'https://schema.org',
            '@graph': [
              getCoporation(),
              {
                '@type': 'WebSite',
                '@id': 'https://swag.live/#website',
                url: 'https://swag.live',
                name: t('seo-home-title', {
                  ns: TranslationNamespace.SEO,
                }),
                description: t('seo-home-description', {
                  ns: TranslationNamespace.SEO,
                }),
                image: {
                  '@id': 'https://swag.live/#logo',
                },
                mainEntity: {
                  '@id': 'https://swag.live/#webpage',
                },
                /* publisher prop will lead google not recogonize logo in Corporation
                publisher: {
                  '@id': 'https://swag.live/#corporation',
                },
                */
                potentialAction: {
                  '@type': 'SearchAction',
                  target: {
                    '@type': 'EntryPoint',
                    urlTemplate: 'https://swag.live/search/all?q={search_term}',
                  },
                  'query-input': 'required name=search_term',
                },
              },
              {
                '@type': 'WebPage',
                '@id': 'https://swag.live/#webpage',
                url: 'https://swag.live',
                name: t('seo-home-title', {
                  ns: TranslationNamespace.SEO,
                }),
                breadcrumb: {
                  '@id': 'https://swag.live/#breadcrumb',
                },
              },
              {
                '@type': 'BreadcrumbList',
                '@id': 'https://swag.live/#breadcrumb',
                itemListElement: [
                  {
                    '@type': 'ListItem',
                    position: 1,
                    name: t('seo-home-title', {
                      ns: TranslationNamespace.SEO,
                    }),
                    item: 'https://swag.live',
                  },
                ],
              },
            ],
          },
          { isJSON: true }
        )}
      </script>
    </Helmet>
  );
};

HomeJsonLd.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(HomeJsonLd);
