// FeedComponent.js
'use strict';
import { connect } from 'react-redux';

import { LIVESTREAM_FEED } from '../RemoteConfigKeys.js';

import FeedComponent from '../component/FeedComponent.jsx';

import getRemoteConfigData from '../selector/getRemoteConfigData.js';

const mapStateToProps = state => {
  return {
    livestreamFeed: getRemoteConfigData(state, LIVESTREAM_FEED),
  };
};

export default connect(mapStateToProps, null)(FeedComponent);
