// withWindowResize.js
import React from 'react';
import env from '../resource/env.js';
import getDisplayName from '../resource/getDisplayName.js';

const withWindowResize = BaseComponent => {
  class withWindowResize extends React.PureComponent {
    state = {
      innerWidth: 0,
      outerWidth: 0,
      outerHeight: 0,
      screenWidth: 0,
      screenHeight: 0,
    };

    componentDidMount() {
      this.resizeListener();
      window.addEventListener('resize', this.resizeListener);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeListener);
    }

    resizeListener = () => {
      // somehow Edge(legacy) browser will cause errro when trying to refercen 'outerWidth' | 'outerHeight'
      try {
        const { screen, innerWidth, innerHeight, outerWidth, outerHeight } =
          window;
        this.setState({
          innerWidth,
          innerHeight,
          outerWidth,
          outerHeight,
          screenWidth: screen.width,
          screenHeight: screen.height,
        });
      } catch (error) {
        return;
      }
    };

    render() {
      return React.createElement(BaseComponent, {
        ...this.props,
        ...this.state,
      });
    }
  }

  if (env.NODE_ENV !== 'production') {
    withWindowResize.displayName = `withWindowResize(${getDisplayName(
      BaseComponent
    )})`;
  }

  return withWindowResize;
};

export default withWindowResize;
