// setShouldPlayFeedId.js
'use strict';
import { SET_OPERATION_DATA } from '../ActionTypes.js';

/**
 * Set should play feed id
 * @kind action
 * @param {string} {feedId} - home feed id.
 * @return {Promise} Action promise.
 */
const setShouldPlayFeedId =
  ({ feedId }) =>
  async dispatch => {
    return dispatch({
      type: SET_OPERATION_DATA,
      payload: {
        selectPath: ['home', 'shouldPlayFeedId'],
        data: feedId,
      },
    });
  };

export default setShouldPlayFeedId;
