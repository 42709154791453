// FeedComponent.jsx
import React from 'react';
import PropTypes from 'prop-types';

import UniversalContainer from '../component/UniversalContainer.jsx';
import UniversalComponent from '../component/UniversalComponent.jsx';

import {
  HomeCategoryUi,
  CategoryLayout,
  CategoryComponentUi,
} from '../resource/feedConstants.js';
import getTranslationKey from '../resource/getTranslationKey.js';

/**
 * @typedef ParsedCategory
 * @property {string} ui - indicate which ui component should use.
 * @property {string} category - category string.
 */

/**
 * Prase category string.
 * @param {string} {category} - category string.
 * @param {boolean} {isLivestreamFeed} - indicate category is livestream feed or not.
 * @param {boolean} {isLastCategory} - indicate category is last item in categories.
 * @return {ParsedCategory}
 */
export const parseCategoryString = ({
  category,
  isLivestreamFeed,
  isLastCategory,
} = {}) => {
  const url = new URL(category, 'https://swag.live');
  let ui = url.searchParams.get('ui') || url.searchParams.get('_ui');
  if (!ui) {
    const isPseudo = /^pseudo_/i.test(category);
    if (isPseudo) {
      ui = undefined;
    } else if (isLastCategory) {
      if (/^(flix|posts)_/.test(category)) {
        ui = HomeCategoryUi.FLIX_GRID;
      } else if (/^livestream_|^user_/.test(category)) {
        ui = HomeCategoryUi.LIVESTREAM_GRID;
      } else {
        ui = HomeCategoryUi.STORY_GRID;
      }
    } else if (/^livestream_/.test(category) || isLivestreamFeed) {
      ui = HomeCategoryUi.LIVESTREAM;
    } else if (/^post_image_/.test(category)) {
      ui = HomeCategoryUi.POST_CARD_GRID;
    } else if (/^(flix|posts|post_video)_/.test(category)) {
      ui = HomeCategoryUi.FLIX;
    } else if (/^story_|stories_|^home$/.test(category)) {
      ui = HomeCategoryUi.STORY;
    } else if (/^short_by_category_/.test(category)) {
      ui = HomeCategoryUi.SHORTS_METADATA_CARD;
    } else if (/^shorts_/.test(category)) {
      ui = HomeCategoryUi.SHORTS;
    } else if (/^user/.test(category)) {
      ui = HomeCategoryUi.USER;
    } else {
      ui = HomeCategoryUi.STORY;
    }
  }
  return {
    category: decodeURIComponent(
      `${url.pathname.replace(/^\//, '')}${url.search}`
    ),
    ui,
  };
};

/**
 * Get user category component's props.
 * @param {HomeCategoryUi} {ui} - category ui.
 * @return {object} Return component props.
 */
const getUserCategoryProps = ({ ui } = {}) => {
  const props = {};
  switch (ui) {
    case HomeCategoryUi.USER_GRID: {
      props.withHalo = true;
      props.isGrid = true;
      break;
    }
    case HomeCategoryUi.USER_SQUARE: {
      props.isSquare = true;
      break;
    }
    case HomeCategoryUi.USER_SQUARE_DOUBLE_ROW: {
      props.isSquare = true;
      props.isDoubleRow = true;
      break;
    }
    case HomeCategoryUi.USER_SQUARE_GRID: {
      props.isSquare = true;
      props.isGrid = true;
      break;
    }
    case HomeCategoryUi.LEADERBOARD_USER_SQUARE: {
      props.isSquare = true;
      props.isLeaderboard = true;
      break;
    }
    case HomeCategoryUi.LEADERBOARD_USER_SQUARE_DOUBLE_ROW: {
      props.isSquare = true;
      props.isLeaderboard = true;
      props.isDoubleRow = true;
      break;
    }
    case HomeCategoryUi.LEADERBOARD_USER_SQUARE_GRID: {
      props.isSquare = true;
      props.isLeaderboard = true;
      props.isGrid = true;
      break;
    }
    case HomeCategoryUi.USER:
    default: {
      props.withHalo = true;
      break;
    }
  }
  return props;
};

export const FeedComponent = props => {
  const {
    livestreamFeed = null,
    category = null,
    titleI18nId = null,
    isLastCategory = false,
  } = props;
  let shouldUseUniversalComponent = false;
  const componentProps = {};
  const parsed = parseCategoryString({
    category,
    isLivestreamFeed: category === livestreamFeed,
    isLastCategory,
  });
  componentProps.category = parsed.category;
  componentProps.titleI18nId =
    titleI18nId || getTranslationKey({ key: category });
  const isPseudo = /^pseudo_/i.test(category);
  if (isPseudo) {
    switch (true) {
      case 'pseudo_banner' === category:
      case 'pseudo_video_banner' === category: {
        componentProps.filename = 'HomeBanner';
        break;
      }
      case ['pseudo_trending_category', 'pseudo_trending_hashtag'].includes(
        category
      ): {
        componentProps.filename = 'HashtagCategoryList';
        break;
      }
      case 'pseudo_leaderboard' === category: {
        componentProps.filename = 'HomeLeaderboard';
        break;
      }
      case 'pseudo_showcase' === category: {
        componentProps.filename = 'HomeShowcaseList';
        break;
      }
      case 'pseudo_livestream_showcase' === category: {
        componentProps.filename = 'LivestreamShowcaseList';
        break;
      }
      case 'pseudo_freezone_button' === category: {
        shouldUseUniversalComponent = true;
        componentProps.filename = 'FreezoneLink';
        break;
      }
      case 'pseudo_livestream_button' === category: {
        shouldUseUniversalComponent = true;
        componentProps.filename = 'LivestreamLink';
        break;
      }
      case /^pseudo_following_stories/i.test(category): {
        componentProps.filename = 'FollowingStoryRow';
        componentProps.shouldShowTitle = true;
        componentProps.shouldShowMoreLink = true;
        componentProps.layout = CategoryLayout.ROW;
        componentProps.componentUi = CategoryComponentUi.MESSAGE_CARD;
        break;
      }
      case /^pseudo_following/i.test(category): {
        componentProps.filename = 'UserCategory';
        componentProps.isFollowing = true;
        const categoryProps = getUserCategoryProps({ ui: parsed.ui });
        Object.keys(categoryProps).forEach(
          key => (componentProps[key] = categoryProps[key])
        );
        break;
      }
      default: {
        return null;
      }
    }
  } else {
    switch (parsed.ui) {
      case HomeCategoryUi.FLIX_GRID: {
        componentProps.filename = 'DiscoverFlixMoreRow';
        break;
      }
      case HomeCategoryUi.FLIX_GRID_MOBILE_FLIX_LIST: {
        componentProps.filename = 'DiscoverFlixMoreRow';
        componentProps.shouldShowListLayoutOnMobile = true;
        break;
      }
      case HomeCategoryUi.FLIX: {
        componentProps.filename = 'DiscoverFlixRow';
        break;
      }
      case HomeCategoryUi.FLIX_LIST: {
        componentProps.filename = 'FeedMessages';
        componentProps.layout = CategoryLayout.LIST;
        componentProps.componentUi = CategoryComponentUi.FLIX_LIST_ITEM;
        break;
      }
      case HomeCategoryUi.LIVESTREAM_GRID:
      case HomeCategoryUi.LIVESTREAM_SQUARE: {
        componentProps.filename = 'HomeLiveStreamFeed';
        componentProps.isSquare =
          HomeCategoryUi.LIVESTREAM_SQUARE === parsed.ui;
        break;
      }
      case HomeCategoryUi.LIVESTREAM: {
        componentProps.filename = 'HomeLiveStreamCategory';
        break;
      }
      case HomeCategoryUi.USER:
      case HomeCategoryUi.USER_GRID:
      case HomeCategoryUi.USER_SQUARE:
      case HomeCategoryUi.USER_SQUARE_DOUBLE_ROW:
      case HomeCategoryUi.USER_SQUARE_GRID:
      case HomeCategoryUi.LEADERBOARD_USER_SQUARE:
      case HomeCategoryUi.LEADERBOARD_USER_SQUARE_DOUBLE_ROW:
      case HomeCategoryUi.LEADERBOARD_USER_SQUARE_GRID: {
        componentProps.filename = 'UserCategory';
        const categoryProps = getUserCategoryProps({ ui: parsed.ui });
        Object.keys(categoryProps).forEach(
          key => (componentProps[key] = categoryProps[key])
        );
        break;
      }
      case HomeCategoryUi.SHORTS: {
        componentProps.filename = 'ShortsRow';
        componentProps.shouldShowTitle = true;
        componentProps.shouldShowMoreLink = true;
        componentProps.layout = CategoryLayout.ROW;
        componentProps.componentUi = CategoryComponentUi.MESSAGE_CARD;
        break;
      }
      case HomeCategoryUi.SHORTS_METADATA_CARD: {
        componentProps.filename = 'ShortsRow';
        componentProps.shouldShowTitle = true;
        componentProps.shouldShowMoreLink = true;
        componentProps.layout = CategoryLayout.ROW;
        componentProps.componentUi = CategoryComponentUi.PROFILE_CARD_ITEM;
        break;
      }
      case HomeCategoryUi.SHORTS_METADATA_CARD_GRID: {
        componentProps.filename = 'ShortsGrid';
        componentProps.layout = CategoryLayout.GRID;
        componentProps.componentUi = CategoryComponentUi.PROFILE_CARD_ITEM;
        break;
      }
      case HomeCategoryUi.POST_FEED: {
        componentProps.filename = 'PostFeed';
        break;
      }
      case HomeCategoryUi.POST_CARD_GRID: {
        componentProps.filename = 'PostCardCategory';
        break;
      }
      case HomeCategoryUi.STORY_GRID: {
        componentProps.filename = 'FeedMessages';
        componentProps.layout = CategoryLayout.GRID;
        componentProps.componentUi = CategoryComponentUi.MESSAGE_CARD;
        break;
      }
      case HomeCategoryUi.STORY_METADATA_CARD: {
        componentProps.filename = 'FeedMessages';
        componentProps.shouldShowTitle = true;
        componentProps.shouldShowMoreLink = true;
        componentProps.layout = CategoryLayout.ROW;
        componentProps.componentUi = CategoryComponentUi.PROFILE_CARD_ITEM;
        break;
      }
      case HomeCategoryUi.STORY_METADATA_CARD_GRID: {
        componentProps.filename = 'FeedMessages';
        componentProps.layout = CategoryLayout.GRID;
        componentProps.componentUi = CategoryComponentUi.PROFILE_CARD_ITEM;
        break;
      }
      case HomeCategoryUi.MESSAGE_LIST: {
        componentProps.filename = 'FeedMessages';
        componentProps.layout = CategoryLayout.LIST;
        componentProps.componentUi = CategoryComponentUi.OUTBOX_MESSAGE;
        componentProps.isImage = true;
        break;
      }
      case HomeCategoryUi.STORY_LIST: {
        componentProps.filename = 'FeedMessages';
        componentProps.layout = CategoryLayout.LIST;
        componentProps.componentUi = CategoryComponentUi.OUTBOX_MESSAGE;
        break;
      }
      case HomeCategoryUi.STORY:
      default: {
        componentProps.filename = 'FeedMessages';
        componentProps.shouldShowTitle = true;
        componentProps.shouldShowMoreLink = true;
        componentProps.layout = CategoryLayout.ROW;
        componentProps.componentUi = CategoryComponentUi.MESSAGE_CARD;
        break;
      }
    }
  }
  if (shouldUseUniversalComponent) {
    return <UniversalComponent {...{ ...componentProps, ...props }} />;
  }
  return <UniversalContainer {...{ ...componentProps, ...props }} />;
};

FeedComponent.propTypes = {
  category: PropTypes.string,
  titleI18nId: PropTypes.string,
  livestreamFeed: PropTypes.string,
  isLastCategory: PropTypes.bool,
};

export default FeedComponent;
